import React from "react"
import { FaArrowRight, FaEnvelope } from "react-icons/fa"

import "../../scss/youmedico.scss"

import SEO from "../../components/seo"
import CardSection from "../../components/cardSection"

import HeaderImg from "../../assets/img/youMedico/telemedicine/headerImg.png"
import Telemedicine from "../../assets/img/youMedico/telemedicine/TelemedicineIcon.svg"
import EPR from "../../assets/img/youMedico/telemedicine/EPRIcon.svg"
import RPM from "../../assets/img/youMedico/telemedicine/RMPIcon.svg"
import AI from "../../assets/img/youMedico/telemedicine/AiIcon.svg"
import GroupImg1 from "../../assets/img/youMedico/telemedicine/LeftSide.png"
import GroupImg2 from "../../assets/img/youMedico/telemedicine/RightSide.png"
import DashboardIcon from "../../assets/img/youMedico/telemedicine/DashboardIcon.svg"
import SyncIcon from "../../assets/img/youMedico/telemedicine/SyncIcon.svg"
import HipaaIcon from "../../assets/img/youMedico/telemedicine/HipaaIcon.svg"
import SecureIcon from "../../assets/img/youMedico/telemedicine/SecureIcon.svg"
import CloudIcon from "../../assets/img/youMedico/telemedicine/CloudIcon.svg"
import NeutronIcon from "../../assets/img/youMedico/telemedicine/NeutronIcon.svg"
import LikeIcon from "../../assets/img/youMedico/telemedicine/LikeIcon.svg"
import TickIcon from "../../assets/img/youMedico/tick.svg"
import DemoButton from "../../components/demoButton"

const YoumedicoTelemedicinePage = () => {
  return (
    <div>
      <SEO
        title="Youmedico"
        keywords={[`youpal`, `youpal group`, `youMedico`, `telemedicine`]}
      />
      <div className="youMedicoMain">
        <section className="container">
          <div className="col">
            <div className="internalMainHeader bugP">
              <h2 className="internalNarrowCol">
                Go remote and let your patients’ number multiply{" "}
              </h2>
            </div>
            <p className="internalNarrowCol">
              With Youmedico the virtual medical care has become even more
              sophisticated and more efficient. With the integrated white-label
              software the platform can provide all the essential features and
              functions, necessary for the virtual medical practice. The most
              modern approach towards telemedicine, Youmedico guarantees the
              full virtualization of your clinic, and the most important part is
              the smooth transferring process.
            </p>
            <DemoButton />
            <img className="headerImg" src={HeaderImg} />
          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
              Our solution for your business
            </h3>
            <p className="internalNarrowCol">
              Youmedico is the solution for modern telemedicine tendencies,
              where both patients and doctors can have sufficient care through
              all necessary tools and features. The core benefits of the
              platform are efficiency, simplicity, intuitive interface, and
              user-friendliness.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Telemedicine} />
              </div>
              <h4>Telemedicine</h4>
              <p>
                The most modern approach to telemedicine, Youmedico solution is
                where virtual medical care is accessible through the integrated
                white-label software, providing features and functions essential
                for medical practice.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={EPR} />
              </div>
              <h4>EPR</h4>
              <p>
                This system gives any health care provider access to every
                electronic medical record of the patient. The records give the
                opportunity to track the condition of the patient and be on
                check for every single change.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={RPM} />
              </div>
              <h4>RPM</h4>
              <p>
                You can review and monitor the data and the health of the
                patient, with all the necessary information. It is capable of
                defining the anomalies, the chronic diseases, and of course
                lower the treatment as well as diagnostic costs.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={AI} />
              </div>
              <h4>AI Symptomes Check</h4>
              <p>
                AI system gives health care professionals the ability to get
                already analyzed data and thus see the whole and the clear
                picture. AI technology enables data collection, monitoring and
                analyzing of the condition.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="text-left internalSmallSecondaryHeader">
                  Analytics
                </div>
                <h3 className="text-left">
                  Live health monitoring with certified tracking devices
                </h3>
                <p>
                  The most time-consuming part of treating the patient is
                  keeping a track of the habits and taking all the necessary
                  samples. The device is an innovative method of having
                  everything in one single place.
                </p>
                <div className="row">
                  <div className="col">
                    <div className="squareHolder">
                      <img src={DashboardIcon} />
                    </div>
                    <h4>Powerful dashboard</h4>
                    <p>
                      A multifunctional device, keeps track of the daily health
                      of the patient and makes records, which are then
                      synchronized to the database.
                    </p>
                  </div>
                  <div className="col">
                    <div className="squareHolder">
                      <img src={SyncIcon} />
                    </div>
                    <h4>Always in Sync</h4>
                    <p>
                      Don’t worry about the data, It will always be synchronized
                    </p>
                  </div>
                </div>
                <div className="buttonArea">
                  <button type="button" className="px-4 btn btn-primary">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">Features</div>
              <h3>Explore about our awesome features</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                netus mi, tellus mattis. Et hac pretium sed iaculis.
              </p>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={HipaaIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>HIPAA copliant</h4>
                      <p>
                        You should never worry about the data security and the
                        product safety, with Youmedico being compliant with all
                        rules.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={SecureIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Secure and fast</h4>
                      <p>
                        It is automatically synchronized. You don’t have to wait
                        in a queue or even see the loading symbol on your
                        screen.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={DashboardIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Powerful dashboard</h4>
                      <p>
                        Organized dashboard is another strong side of Youmedico,
                        which makes it easy to navigate through the website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={CloudIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Cloud</h4>
                      <p>
                        Youmedico cloud database gathers patients’ information,
                        which can be accumulated and examined to improve
                        patients’ overall health.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={NeutronIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Secure and fast</h4>
                      <p>
                        It is automatically synchronized. You don’t have to wait
                        in a queue or even see the loading symbol on your
                        screen.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={LikeIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Custom Domain</h4>
                      <p>
                        Via providing custom domains, Youmedico gives you access
                        to dashboard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">Collaborate</div>
                <h3>Collaborate with your team anytime, anywhere.</h3>
                <p>
                  Colleboare with your team, manage your mediacal staff, and be
                  flexible with appointemenst and your patients.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Organize your data
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Always in sync
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Work with any team
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Embedded analytics
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Business analytics
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <CardSection />

        <section className="container">
          <div className="row contactBox">
            <div className="col">
              <h2>Contact us for pricing</h2>
              <p className="subtitle">
                If you have come this far, this means that you care about the
                future of your clinic and patients, and we will be happy to
                deleiver the best price package for you.{" "}
              </p>
              <div className="sendLinkContainer">
                <div className="input-group">
                  <FaEnvelope size={25} />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                    aria-label="Your Email"
                    aria-describedby="button-addon2"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default YoumedicoTelemedicinePage
